import { useMutation } from "react-query";
import ApiClient from "../../../utils/apiClient";
import { exportCircuitsUrl } from "../../../api";

export const useExportCircuits = (reportId: string, programId: string) => {
  return useMutation(() =>
    ApiClient.post(exportCircuitsUrl, {
      reportId: reportId,
      parameters: {
        programId,
      },
    }).then((res) => res.data)
  );
};
