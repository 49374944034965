import { getEnvValue } from "./get-env";

export type EXTENT_PARAMS = {
  zoomLevel: number;
  minLatitude: number;
  maxLatitude: number;
  minLongitude: number;
  maxLongitude: number;
};

const maxObjectCount = getEnvValue("REACT_APP_MAX_OBJECT_COUNT") ?? 250;

export const getFeedersApiUrl = () => `/feeders`;

export const getRefreshTokenApiUrl = () => `/auth/refresh`;

export const getImagerySourcesApiUrl = () => `/sources`;

export const imagerySourcesApiUrl = `/sources`;

export const getFeederVoltageUrl = () => "/voltages";

export const getFeederVoltageEntryUrl = (id = "") => `${getFeederVoltageUrl()}/${id}`;

export const getElevationUrl = () => "/elevation-levels";

export const getElevationEntryUrl = (id = "") => `/elevation-levels/${id}`;

export const getStructuresAdminUrl = () => "/structures/types";

export const getStructuresAdminEntryUrl = (id = "") => `/structures/types/${id}`;

export const getFeedersApiUrlByExtent = ({
  minLongitude,
  maxLongitude,
  minLatitude,
  maxLatitude,
  zoomLevel,
}: {
  minLongitude: number;
  maxLongitude: number;
  minLatitude: number;
  maxLatitude: number;
  zoomLevel: number;
}) =>
  `/feeders?minLongitude=${minLongitude}&maxLongitude=${maxLongitude}&minLatitude=${minLatitude}&maxLatitude=${maxLatitude}&zoomLevel=${zoomLevel}`;

export const getStructuresApiUrlByExtent = ({
  minLongitude,
  maxLongitude,
  minLatitude,
  maxLatitude,
  zoomLevel,
}: {
  minLongitude: number;
  maxLongitude: number;
  minLatitude: number;
  maxLatitude: number;
  zoomLevel: number;
}) =>
  `/structures?minLongitude=${minLongitude}&maxLongitude=${maxLongitude}&minLatitude=${minLatitude}&maxLatitude=${maxLatitude}&zoomLevel=${zoomLevel}&maxCount=${maxObjectCount}`;

export const getVectorLayersByExtent = ({
  minLongitude,
  maxLongitude,
  minLatitude,
  maxLatitude,
  zoomLevel,
  url,
}: {
  minLongitude: number;
  maxLongitude: number;
  minLatitude: number;
  maxLatitude: number;
  zoomLevel: number;
  url: string;
}) =>
  `${url}?minLongitude=${minLongitude}&maxLongitude=${maxLongitude}&minLatitude=${minLatitude}&maxLatitude=${maxLatitude}&zoomLevel=${zoomLevel}`;

export const getVectorsApiUrl = () => "/vectors";

export const getUrbanAreasApiUrl = () => `${getVectorsApiUrl()}/urban-areas`;

export const getCountiesApiUrl = () => `${getVectorsApiUrl()}/counties`;

export const vectorLayersUrl = "/vector-layers";

export const getParcelsApiUrl = ({
  minLongitude,
  maxLongitude,
  minLatitude,
  maxLatitude,
  zoomLevel,
}: {
  minLongitude: Nullable<number>;
  maxLongitude: Nullable<number>;
  minLatitude: Nullable<number>;
  maxLatitude: Nullable<number>;
  zoomLevel: Nullable<number>;
}) =>
  `/parcels?minLongitude=${minLongitude}&maxLongitude=${maxLongitude}&minLatitude=${minLatitude}&maxLatitude=${maxLatitude}&zoomLevel=${zoomLevel}&maxCount=${maxObjectCount}`;

export const getRightOfWayOverridesApiUrl = () => "/row-overrides";

export const deleteRightOfWayOverridesEntryApiUrl = (id: string) => `${getRightOfWayOverridesApiUrl()}/${id}`;

export const getCutPlanApiUrl = () => "/cut-plan";

export const cutPlanExportApiUrl = `${getCutPlanApiUrl()}/export`;

export const getTreeCanopyUrl = ({
  minLongitude,
  maxLongitude,
  minLatitude,
  maxLatitude,
  zoomLevel,
}: {
  minLongitude: number;
  maxLongitude: number;
  minLatitude: number;
  maxLatitude: number;
  zoomLevel: number;
}) =>
  `/tree-canopies?minLongitude=${minLongitude}&maxLongitude=${maxLongitude}&minLatitude=${minLatitude}&maxLatitude=${maxLatitude}&zoomLevel=${zoomLevel}`;

export const getWorkTypesApiUrl = () => "/work-types";

export const getAdminWorkTypesApiUrl = () => `/admin${getWorkTypesApiUrl()}`;

export const getAdminWorkTypesEntryApiUrl = (id: string) => `${getAdminWorkTypesApiUrl()}/${id}`;

export const getWorkTypesRatesApiUrl = () => `${getAdminWorkTypesApiUrl()}/rates`;

export const getThreatWorksheetApiUrl = (id: string) => `/threats/${id}/worksheet`;

export const getSpanWorksheetApiUrl = (id: string) => `/worksheet/spans/${id}`;

export const getPredominatedWorkTypesApiUrl = () => "/worksheet/spans/predominant-work";

export const getParcelStatusUpdateUrl = (parcelId: string) => `/parcels/${parcelId}/status`;

export const getParcelContactUpdateUrl = (parcelId: string) => `/parcels/${parcelId}/contact`;

export const getFeederSegmentsUrl = () => "/feeder-segments";

export const getWorksheetsForStructuresUrl = () => "/structures/worksheets";

export const getStructureWorksheetApiUrl = (id: string) => `/structures/${id}/worksheet`;

export const putBulkParcelsStatus = () => `/parcels/status`;

export const getWorksheetsForSpansUrl = () => "/worksheet/spans/all";

export const getAllWorksheetsForSpanUrl = (bulkUpdate = false) => `/spans/worksheets?bulkUpdate=${bulkUpdate}`;

export const getAllWorksheetsForThreatUrl = (id: string) => `/threats/${id}/worksheets-all`;

export const maintenanceAreas = "/maintenance-areas";

export const getFeederSegmentsByExtentUrl = ({
  minLongitude,
  maxLongitude,
  minLatitude,
  maxLatitude,
  zoomLevel,
}: {
  minLongitude: Nullable<number>;
  maxLongitude: Nullable<number>;
  minLatitude: Nullable<number>;
  maxLatitude: Nullable<number>;
  zoomLevel: Nullable<number>;
}) =>
  `/feeder-segments?minLongitude=${minLongitude}&maxLongitude=${maxLongitude}&minLatitude=${minLatitude}&maxLatitude=${maxLatitude}&zoomLevel=${zoomLevel}&maxCount=${maxObjectCount}`;

export const getMaintenanceAreasAlliUrl = ({ lat, lon }: { lat: Nullable<number>; lon: Nullable<number> }) =>
  `${maintenanceAreas}/all?lat=${lat}&lon=${lon}`;

export const getMaintenanceAreasApiUrl = ({
  minLongitude,
  maxLongitude,
  minLatitude,
  maxLatitude,
  zoomLevel,
}: {
  minLongitude: Nullable<number>;
  maxLongitude: Nullable<number>;
  minLatitude: Nullable<number>;
  maxLatitude: Nullable<number>;
  zoomLevel: Nullable<number>;
}) =>
  `/maintenance-areas?minLongitude=${minLongitude}&maxLongitude=${maxLongitude}&minLatitude=${minLatitude}&maxLatitude=${maxLatitude}&zoomLevel=${zoomLevel}`;

export const putParcelInfoUrl = (parcelId: string) => `/parcels/${parcelId}`;

export const alertsUrl: string = "/markers";
export const deleteAlert = (id: string) => `${alertsUrl}/${id}`;
export const getAlertsCategories = () => `${alertsUrl}/categories`;
export const postAlerts = () => `${alertsUrl}/categories`;
export const searchAlerts = `${alertsUrl}/search`;

export const postCommentUrl = () => "/comments";

export const postRepliesUrl = (commentId: string) => `/comments/${commentId}/replies`;

export const searchCommentsUrl = () => `/comments/search`;

export const putStatusCommentUrl = (commentId: string, state: "resolve" | "unresolve" | "read") =>
  `/comments/${commentId}/${state}`;

export const deleteCommentUrl = (commentId: string) => `/comments/${commentId}`;

export const deleteReplyUrl = (replyId: string) => `/comments/replies/${replyId}`;
export const getAttachementsUrl = (
  entityId: string,
  entityType: string,
  size: string,
  waitForProcessing: boolean = false
) => `/attachments?entityId=${entityId}&entityType=${entityType}&size=${size}&waitForProcessing=${waitForProcessing}`;

export const uploadAttachementsUrl = (entityId: string, entityType: string) =>
  `/attachments?entityId=${entityId}&entityType=${entityType}`;

export const updateAttachmentDescriptionUrl = (entityId: string) => `/attachments/${entityId}/description`;

export const deleteAttachmentUrl = (attachmentId: string) => `/attachments/${attachmentId}`;

export const getAttachmentUrl = (attachmentId: string, size: string, waitForProcessing: boolean = false) =>
  `/attachments/${attachmentId}?size=${size}&waitForProcessing=${waitForProcessing}`;

export const deleteInfringementWorkTypeUrl = (spanId: string, workTypeId: string) =>
  `/spans/${spanId}/threat-worksheets/${workTypeId}`;

//tree markers
export const treeMarkersUrl = "/tree-markers";

export const getTreeMarkersUrl = ({
  minLongitude,
  maxLongitude,
  minLatitude,
  maxLatitude,
  zoomLevel,
}: {
  minLongitude: Nullable<number>;
  maxLongitude: Nullable<number>;
  minLatitude: Nullable<number>;
  maxLatitude: Nullable<number>;
  zoomLevel: Nullable<number>;
}) =>
  `${treeMarkersUrl}?minLongitude=${minLongitude}&maxLongitude=${maxLongitude}&minLatitude=${minLatitude}&maxLatitude=${maxLatitude}&zoomLevel=${zoomLevel}`;

export const getTreeMarkerCategories = () => `${treeMarkersUrl}/categories`;

export const getDeleteTreeMarkerUrl = (markerId: string) => `${treeMarkersUrl}/${markerId}`;

export const customPolygonUrl: string = "/threats";

export const threatsBulkUrl = `${customPolygonUrl}/bulk`;

export const updatePolygonUrl = (id: string) => `/threats/${id}`;

//access-points
export const accessPointsUrl = "/access-points";

export const getAccessPointsUrl = ({
  minLongitude,
  maxLongitude,
  minLatitude,
  maxLatitude,
  zoomLevel,
}: {
  minLongitude: Nullable<number>;
  maxLongitude: Nullable<number>;
  minLatitude: Nullable<number>;
  maxLatitude: Nullable<number>;
  zoomLevel: Nullable<number>;
}) =>
  `${accessPointsUrl}?minLongitude=${minLongitude}&maxLongitude=${maxLongitude}&minLatitude=${minLatitude}&maxLatitude=${maxLatitude}&zoomLevel=${zoomLevel}`;

export const getAccessPointsCategories = () => `${accessPointsUrl}/categories`;

export const getAccessPointsMarkerUrl = (markerId: string) => `${accessPointsUrl}/${markerId}`;

export const plansUrl = "/plans";
export const programsUrl = "/programs";
export const getProgramTaxonomyNodeUrl = (programId: string, nodeId: string) =>
  `/programs/${programId}/taxonomy-nodes/${nodeId}`;
export const getWorkOrderPreviewUrl = (programId: string, nodes?: string[]) => {
  if (nodes?.length) {
    return `/programs/${programId}/bulk-work-orders?${nodes
      .map((node) => `circuitTaxonomyNodesIds=${node}`)
      .join("&")}`;
  }

  return `/programs/${programId}/bulk-work-orders`;
};

export const getBulkAssign = (programId: string) => `/programs/${programId}/bulk-assign`;

export const getPlanUrl = (id?: string) => `/plans/${id}`;

export const getProgramsForPlanUrl = (id?: string) => `/plans/${id}/programs`;
export const getSingleProgramUrl = (programId?: string) => `/programs/${programId}`;

export const regionGroupsUrl = "/region-groups";

export const crewsUrl = "/crews";
export const crewsUrlV2 = "/v2/crews";
export const crewsIdUrl = (id: string) => `${crewsUrl}/${id}`;

export const updateCrewNameUrl = (id: string) => `/crews/${id}/name`;

export const deleteContractorRoleUrl = (crewId: string, roleId: string) =>
  `${crewsUrl}/${crewId}/contractor-roles/${roleId}`;

export const putContractorRoleUrl = (id: string) => `${crewsUrl}/${id}/contractor-roles`;

export const putCompatibleWorkUrl = (id: string) => `${crewsUrl}/${id}/compatible-work-types`;

export const updateNumberOfPeopleUrl = (crewId: string, contractorRoleId: string) =>
  `${crewsUrl}/${crewId}/contractor-roles/${contractorRoleId}/number-of-people`;

export const updateCrewConfigUrl = (crewId: string) => `${crewsUrl}/${crewId}/contractor-rates`;

export const updateNumberOfPeople = (crewId: string, contractorRoleId: string) =>
  `/crews/${crewId}/contractor-roles/${contractorRoleId}/number-of-people`;

export const skillsUrl = "/skills";

export const rolesCrewsUrl = "/contractor-roles";

export const crewsConfigurationUrl = "/crews-configuration";

export const addRoleUrl = "/add-role";

export const starCommandUrl = "/star-command/feeders";

export const starCommandMAreasUrl = "/star-command/maintenance-areas/query";

export const postSatelliteQueryUrl = "/satellite/query";

export const getThreatsUrl = ({
  minLongitude,
  maxLongitude,
  minLatitude,
  maxLatitude,
  zoomLevel,
  sourceId,
}: {
  minLongitude: Nullable<number>;
  maxLongitude: Nullable<number>;
  minLatitude: Nullable<number>;
  maxLatitude: Nullable<number>;
  zoomLevel: Nullable<number>;
  sourceId: string;
}) =>
  `/threats?minLongitude=${minLongitude}&maxLongitude=${maxLongitude}&minLatitude=${minLatitude}&maxLatitude=${maxLatitude}&zoomLevel=${zoomLevel}&sourceId=${sourceId}&maxCount=${maxObjectCount}`;

export const getThreatsInfoUrl = (id: string) => `/threats/${id}`;

export const getSpansByExtentUrl = ({
  minLongitude,
  maxLongitude,
  minLatitude,
  maxLatitude,
  zoomLevel,
}: {
  minLongitude: Nullable<number>;
  maxLongitude: Nullable<number>;
  minLatitude: Nullable<number>;
  maxLatitude: Nullable<number>;
  zoomLevel: Nullable<number>;
}) =>
  `/spans?minLongitude=${minLongitude}&maxLongitude=${maxLongitude}&minLatitude=${minLatitude}&maxLatitude=${maxLatitude}&zoomLevel=${zoomLevel}`;

export const getSpanInfoUrl = (id: string) => `/spans/${id}`;

export const patrolUrl = "/patrols";
export const patrolSearchUrl = "/patrols/search";
export const patrolsByIDsUrl = "/patrols/ids";
export const problemCodesUrl = "/ranger/problem-codes";

export const maxarOrderUrl = "/satellite-orders?sort=id,desc";

export const getStructureByIdUrl = (id: string) => `/structures/${id}`;

export const memberTypesUrl = "/crew-member-types";
export const equipmentTypesUrl = "/equipment-types";
export const crewTypesUrl = "/crew-types";
export const equipmentsUrl = "/equipments";

export const materialsUrl = "/materials";

export const workOrderUrl = "/work-orders";
export const workOrderStatusesUrl = "/work-orders/statuses";
export const workOrderPrioritiesUrl = "/work-orders/priorities";
export const getWorkOrdersByObjectId = (id: string) => `${workOrderUrl}?objectId=${id}`;
export const getWorkOrderById = (id: string) => `${workOrderUrl}/${id}`;

export const deviceLocationUrl = () => "/device-location";

export const getParcelRecordForParcelUrl = (id: string) => `/parcels/${id}/parcel-records`;
export const getParcelRecordCategoriesUrl = () => `/parcels/parcel-records/categories`;

export const taxonomyUrl = () => "/taxonomies";

export const taxonomyNodeUrl = (taxonomyId: string) => `/taxonomies/${taxonomyId}/nodes`;
export const taxonomyMetadataUrl = (taxonomyId: string) => `/taxonomies/nodes/${taxonomyId}/spans/metadata`;

export const tileCookieUrl = () => "/tiles/cookie";
export const rateTypesUrl = "/rates/types";
export const getRatesSheetUrl = (typeId: string, vendorId?: string) =>
  vendorId ? `${rateTypesUrl}/${typeId}/vendor/${vendorId}/sheet` : `${rateTypesUrl}/${typeId}/sheet`;
export const addRateItemUrl = `rates/item`;
export const getRateEditItemUrl = (id: string) => `${addRateItemUrl}/${id}`;

export const vendorUrl = "/vendors";

export const getImageCategoriesUrl = () => "/satellite/image-age-categories";

export const notificationUrl = (token: Maybe<string>) => `/vantage-notifications?token=${token}`;

export const treeSpeciesUrl = "/tree-species";

export const getLiteProgramsUrl = (planId: string) => `/plans/${planId}/programs/lite`;
export const getAddToPlanUrl = (programId: string) => `/programs/${programId}/taxonomy-nodes`;

export const getWildfireClustersByExtent = ({
  minLongitude,
  maxLongitude,
  minLatitude,
  maxLatitude,
  zoomLevel,
  fromDate,
  toDate,
}: {
  minLongitude: number;
  maxLongitude: number;
  minLatitude: number;
  maxLatitude: number;
  zoomLevel: number;
  fromDate: string;
  toDate: string;
}) =>
  `vectors/wildfire-clusters/timeline?minLongitude=${minLongitude}&maxLongitude=${maxLongitude}&minLatitude=${minLatitude}&maxLatitude=${maxLatitude}&zoomLevel=${zoomLevel}&fromDate=${fromDate}&toDate=${toDate}&includeGeojson=true`;

export const getWildfireClustersByDateRangeUrl = ({ fromDate, toDate }: { fromDate: string; toDate: string }) =>
  `vectors/wildfire-clusters/timeline?fromDate=${fromDate}&toDate=${toDate}&includeGeojson=true`;

export const getWildfireSpread = (asOf: string) => `vectors/wildfire-spread?asOf=${asOf}`;

export const reportsUrl = "/reports";

export const getReportsUrl = "/reports/execute";

export const exportCircuitsUrl = `${reportsUrl}/export`;

export const getWeatherLayerUrl = (layerCode: string, timestamp: string, apiKey: string | undefined) => {
  return `https://api.tomorrow.io/v4/map/tile/{z}/{x}/{y}/${layerCode}/${timestamp}.png?apikey=${apiKey}`;
};

export const postSearchTaxonomyURl = "/taxonomies/search";

export const taxonomyConfigurationURL = "/taxonomies/configuration";

export const getParcelStatuses = "/parcels/statuses";
