import { useRef, useState, UIEvent, useEffect } from "react";
import { useVirtual } from "@tanstack/react-virtual";
import {
  useTable,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  useExpanded,
  useRowSelect,
  Row,
  useMountedLayoutEffect,
} from "react-table";
import { StyledFlex } from "../../../../assets/styles/flex.styled";
import { DashboardNodeDto } from "../../../reports/types";
import { ProgramsEmptyState } from "../../../planning-mode/components/ProgramsForPlan/ProgramsForPlan.styled";
import { TableWrapper } from "./TaxonomyNodesTable.styled";
import { useDashboardSpanData } from "../../hooks/useDashboardSpanData";
import { StatusesType } from "../../types";
import { useCircuitColumns } from "../../hooks/useCircuitColumns";
import { useDashboardStore } from "../../stores/DashboardStore";
import { Spinner } from "../../../../components/Loader/Spinner";

const CONTENT_HEIGHT_ABOVE_TABLE = "365px";

export const defaultColumn = {
  minWidth: 30,
  width: 150,
  maxWidth: 400,
};

interface IProps {
  data?: DashboardNodeDto[] | null;
  isDashboardDataLoading: boolean;
  isFetched: boolean;
  selectedProgramId: string | undefined;
  selectedStatus: StatusesType;
  setSelectedCircuitId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const TaxonomyNodesTable = ({
  data,
  selectedStatus,
  selectedProgramId,
  isFetched,
  isDashboardDataLoading,
  setSelectedCircuitId,
}: IProps) => {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [lastClickedRow, setLastClickedRow] = useState<Row<DashboardNodeDto> | undefined>(undefined);
  const setRows = useDashboardStore((store) => store.setSelectedRows);

  const columns = useCircuitColumns({ isLoading: isDashboardDataLoading });
  const {
    rows,
    headerGroups,
    selectedFlatRows,
    prepareRow,
    getTableProps,
    getTableBodyProps,
    toggleAllRowsExpanded,
    toggleAllRowsSelected,
  } = useTable(
    {
      data: data || [],
      columns,
      autoResetResize: false,
      autoResetExpanded: true,
      autoResetSortBy: false,
    },
    useResizeColumns,
    useFlexLayout,
    useSortBy,
    useExpanded,
    useRowSelect
  );

  const { isLoading: areSpansLoading, mutateAsync: getSpanData } = useDashboardSpanData({
    programId: selectedProgramId,
    status: selectedStatus,
  });

  const rowVirtualizer = useVirtual({
    parentRef: parentRef,
    size: rows.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  useEffect(() => {
    toggleAllRowsExpanded(false);
    toggleAllRowsSelected(false);
  }, [selectedStatus, toggleAllRowsExpanded, toggleAllRowsSelected]);

  useMountedLayoutEffect(() => {
    if (isFetched) {
      setRows(selectedFlatRows.filter((row) => row.depth === 0));
    }
  }, [isFetched, selectedFlatRows]);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => setIsScrolled(e.currentTarget.scrollTop > 0);

  if (isDashboardDataLoading === false && isFetched === false) {
    return (
      <StyledFlex justifyContent={"center"} p={"20px"}>
        <Spinner />
      </StyledFlex>
    );
  }

  if (isFetched && data?.length === 0) {
    return (
      <StyledFlex justifyContent={"center"} p={"20px"}>
        <ProgramsEmptyState>No data</ProgramsEmptyState>
      </StyledFlex>
    );
  }

  return (
    <TableWrapper
      ref={parentRef}
      style={{
        maxHeight: `calc(100vh - ${CONTENT_HEIGHT_ABOVE_TABLE})`,
        height: `calc(100vh - ${CONTENT_HEIGHT_ABOVE_TABLE})`,
        overflow: "auto",
      }}
      onScroll={handleScroll}
    >
      <div {...getTableProps()} className="table">
        <div className={`thead ${isScrolled ? "isScrolled" : ""}`}>
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps(column.getSortByToggleProps())} className="td">
                  {column.render("Header")}
                  {column.canResize && (
                    <div {...column.getResizerProps()} className={`resizer ${column.isResizing ? "isResizing" : ""}`} />
                  )}
                  <span>{column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}</span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} className="tbody">
          {paddingTop > 0 && (
            <div>
              <div style={{ height: `${paddingTop}px`, opacity: 0 }} />
            </div>
          )}

          {virtualRows.map((virtualRow) => {
            const row = rows.at(virtualRow.index)!;
            prepareRow(row);

            return (
              <div
                {...row.getRowProps()}
                className={`tr ${row.isExpanded ? "isExpanded" : ""} ${row.depth !== 0 ? "subRow" : ""}`}
              >
                {row.cells.map((cell) => (
                  <div {...cell.getCellProps()} className="td">
                    {cell.render("Cell", {
                      isLoading: lastClickedRow?.id === row?.id ? areSpansLoading : false,
                      handleCostBenefitOnClick: setSelectedCircuitId,
                      handleExpand: async (row: Row<DashboardNodeDto>) => {
                        if (row?.isExpanded) {
                          setLastClickedRow(undefined);
                          row?.toggleRowExpanded(false);
                          return;
                        }

                        lastClickedRow?.toggleRowExpanded(false);
                        setLastClickedRow(row);

                        // @ts-ignore
                        if (!row?.isLoading) {
                          if (!row?.isExpanded) {
                            if (row?.subRows === undefined || row?.subRows.length === 0) {
                              await getSpanData(row?.original.taxonomyNodeId!);
                            }
                          }
                          row?.toggleRowExpanded(true);
                        }
                      },
                    })}
                  </div>
                ))}
              </div>
            );
          })}

          {paddingBottom > 0 && (
            <div>
              <div style={{ height: `${paddingBottom}px`, opacity: 0 }} />
            </div>
          )}
        </div>
      </div>
    </TableWrapper>
  );
};
