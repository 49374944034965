import create from "zustand";
import { EMPTY_FN } from "../../../constants";
import { ParcelStatus } from "../types/ParcelsResponse";

export type ParcelsStore = {
  selectedId: Nullable<string>;
  parcelLayerHidden: boolean;
  isAllSelected: boolean;
  parcelStatuses: Nullable<ParcelStatus[]>;
  selectedStatuses: Record<string, boolean>;
  statusesMap: Record<string, ParcelStatus>;
  toggleParcelLayer: () => void;
  resetStore: () => void;
  setSelectedId: (id: Nullable<string>) => void;
  toggleSelectedStatus: (status: string) => void;
  toggleAll: () => void;
  selectedMultipleIds: Array<Nullable<string>>;
  setMultipleSelectedIds: (id: any) => void;
  setParcelLayer: (value: boolean) => void;
  setParcelStatuses: (statuses: Nullable<ParcelStatus[]>) => void;
};

const INITIAL_STATE = {
  selectedId: null,
  resetStore: EMPTY_FN,
  parcelLayerHidden: true,
  isAllSelected: true,
  selectedMultipleIds: [],
  parcelStatuses: null,
  selectedStatuses: {},
  statusesMap: {},
};

export const useParcelsStore = create<ParcelsStore>((set, get) => ({
  ...INITIAL_STATE,
  resetStore: () => set({ ...INITIAL_STATE }),
  setSelectedId: (id) => {
    if (id === get().selectedId) {
      return;
    }
    set({ selectedId: id });
    // if one parcel is selected, empty the multiselected parcels
    set({ selectedMultipleIds: [] });
  },
  toggleParcelLayer: () => {
    set({ parcelLayerHidden: !get().parcelLayerHidden });
  },

  toggleSelectedStatus: (status) => {
    const statuses = { ...get().selectedStatuses };
    statuses[status] = !statuses[status];
    set({ isAllSelected: isAllChecked(get().parcelStatuses, statuses), selectedStatuses: statuses });
  },

  toggleAll: () => {
    const isAllSelected = get().isAllSelected;
    const selectedStatuses = { ...get().selectedStatuses };
    if (!selectedStatuses || !Object.keys(selectedStatuses).length) {
      return;
    }
    Object.keys(selectedStatuses).forEach((key) => {
      selectedStatuses[key] = !isAllSelected;
    });
    set({ selectedStatuses: selectedStatuses, isAllSelected: !isAllSelected });
  },
  setMultipleSelectedIds: (id: Nullable<string>) => {
    set({ selectedMultipleIds: [...get().selectedMultipleIds, id] });
  },
  setParcelLayer: (value: boolean) => {
    set({ parcelLayerHidden: value });
  },
  setParcelStatuses: (statuses) => {
    const selectedStatuses: Record<string, boolean> = {};
    const map: Record<string, ParcelStatus> = {};
    statuses?.forEach((status) => {
      selectedStatuses[status.name] = true;
      map[status.name] = status;
    });

    set({ parcelStatuses: statuses, selectedStatuses: selectedStatuses, statusesMap: map });
  },
}));

const isAllChecked = (allStatuses: Nullable<ParcelStatus[]>, statusState: Record<string, boolean>) => {
  if (!statusState || !Object.keys(statusState).length || !allStatuses || !allStatuses?.length) {
    return false;
  }

  return allStatuses.length === Object.keys(statusState).filter((key) => statusState[key])?.length;
};

export default useParcelsStore;
