import styled from "styled-components";
import { StyledFlex } from "../../../../assets/styles/flex.styled";

export const TableWrapper = styled.div`
  width: calc(100% - 8px);
  color: var(--colors-slate-500);
  font-size: 14px;
  border-spacing: 0px 8px;
  background-color: var(--colors-white);
  margin-top: 10px;

  ::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  * {
    box-sizing: border-box;
  }

  .table {
    width: fit-content;
  }

  .thead {
    height: 25px;
    position: sticky;
    top: 0;
    background-color: var(--colors-white);
    z-index: 1;

    &.isScrolled {
      box-shadow: 0 6px 6px -2px rgba(0, 0, 0, 0.1);
    }

    .tr {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #64748b;
    }
  }

  .tbody .tr.isExpanded {
    .td:first-child {
      border-bottom-left-radius: 0px;
    }

    .td:last-child {
      border-bottom-right-radius: 0px;
    }
  }

  .tr {
    gap: 10px;
  }

  .tbody .tr {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
  }

  .tbody .tr.subRow {
    background-color: #f1f5f9;
  }

  .tbody .tr .td {
    display: inline-flex;
    align-items: center;
    min-height: 45px;
    white-space: nowrap;

    & .truncText {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .tbody,
  * + .tr:not(.subRow) {
    margin-top: 8px;
  }

  .resizer {
    display: inline-block;
    background: gray;
    width: 5px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    /* prevent scroll on touch devices */
    touch-action: none;
    opacity: 0;
    border-radius: 20px;

    &:hover,
    &.isResizing {
      opacity: 1;
    }
  }

  .isResizing {
    background: red;
  }

  .td {
    display: inline-flex;

    .rightAlignText {
      margin-left: auto;
    }

    .centerText {
      margin: 0 auto;
    }
  }

  .dollarValue {
    font-family: "JetBrains Mono", monospace;
    font-size: 13px;
  }
`;

export const Expander = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 8px;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  margin: auto;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }

  svg {
    flex-shrink: 0;
    transition: transform 0.3s ease-in-out;

    path {
      fill: #6b7280;
    }
  }

  &.is-expanded {
    svg {
      transform: rotateZ(180deg);
    }
  }
`;

export const GotoMapButton = styled.button`
  border: none;
  padding: 0;
  background: transparent;

  svg {
    width: 18px;
    height: 16px;
  }

  &:hover {
    cursor: pointer;

    svg {
      transform: scale(1.1);
    }
  }
`;

export const CellSkeleton = styled.span`
  width: 100%;
  display: block;
  height: 23px;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;

export const WorktypeCount = styled.span`
  line-height: 24px;

  &:hover {
    cursor: pointer;

    div {
      display: flex;
    }
  }
`;

export const HoverWrapper = styled(StyledFlex)`
  display: none;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(98%, 95%);
  max-height: 300px;
  overflow: auto;
  z-index: 999;
  background: #334155;
  color: white;
  padding: 10px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
`;

export const LastWorktypeWrapper = styled.div`
  position: relative;

  &.hasData:hover {
    cursor: pointer;

    div {
      display: flex;
    }
  }
`;

export const Header = styled.span`
  text-wrap: nowrap;
`;

export const CostBenefit = styled.button`
  --text-color: #64748b;
  --border-color: transparent;
  --background-color: transparent;
  font-size: 14px;
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--background-color);
  padding: 4px 8px;
  text-align: center;

  &.isCircuitLabel {
    width: 70px;
    font-weight: 600;
    --text-color: #64748b;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }

  svg {
    margin-left: 5px;

    path {
      fill: var(--border-color);
    }
  }

  &[data-priority="red"] {
    --text-color: var(--colors-persimmon);
    --border-color: #f2c6c6;
    --background-color: #fff8f8;
  }

  &[data-priority="green"] {
    --text-color: #16a34a;
    --border-color: #91d080;
    --background-color: #fafdf9;
  }

  &[data-priority="yellow"] {
    --text-color: #d97706;
    --border-color: #e7d3bd;
    --background-color: #fefef6;
  }
`;
