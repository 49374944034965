import { Suspense, lazy, useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import fileDownload from "js-file-download";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { ReactComponent as Plus } from "../../assets/images/plus.svg";
import { ReactComponent as Trash } from "../../assets/images/remove.svg";
import { ReactComponent as ExportIcon } from "../../assets/images/export.svg";
import { ReactComponent as Filters } from "../../assets/images/filter-icon.svg";
import { ReactComponent as Magnifier } from "../../assets/images/magnifier.svg";
import { ReactComponent as CloseSearch } from "../../assets/images/close.svg";
import { Input } from "../../components/Input/Input.styled";
import { Spinner } from "../../components/Loader/Spinner";
import usePlans, { PLANS_QUERY_KEY } from "../../features/planning/hooks/usePlans";
import { Header } from "../../features/planning-dashboard/components/Header";
import { StatusRadio } from "../../features/planning-dashboard/components/StatusRadio";
import { StatusesType } from "../../features/planning-dashboard/types";
import {
  filterBySearchTerm,
  filterByVoltage,
  getCtaLabel,
  mapTableData,
  shouldShowCta,
} from "../../features/planning-dashboard/utils";
import { usePlanningStore } from "../../features/planning-mode/stores";
import { useProgramsForPlan, useProgramsLite } from "../../features/planning-mode/hooks";
import { useDashboardReportsData } from "../../features/planning-dashboard/hooks/useDashboardReportsData";
import {
  PageWrapper,
  ContentWrapper,
  MainContent,
  SearchWrapper,
  RemoveButton,
  CtaButton,
  FilterButton,
  ExportButton,
} from "./PlanningDashboard.styled";
import { WorkTypeResponse } from "../../types/responses";
import { DashboardNodeDto } from "../../features/reports/types";
import { Modal } from "../../features/planning-mode/components/Modal";
import { CreateWorkOrderData } from "../../features/planning-mode/components/WorkOrderBulk/WorkOrderBulk";
import useWorkOrderPreview from "../../features/planning-mode/hooks/useWorkOrderPreview";
import useWorkOrderBulkCreate from "../../features/planning-mode/hooks/useWorkOrderBulkCreate";
import { SpinnerWrapper } from "../PlanningMode/PlanningMode.styled";
import { MessageType, useMessageStore } from "../../stores";
import { SOMETHING_WENT_WRONG_MESSAGE } from "../../constants";
import { FeederType } from "../../features/planning-dashboard/components/AssignToGF";
import { useBulkAssign } from "../../features/planning-dashboard/hooks/useBulkAssign";
import { StatusSkeleton } from "../../features/planning-dashboard/components/StatusRadio/StatusSkeleton";
import { useClickOutside, useCustomQuery } from "../../hooks";
import { getWorkTypesApiUrl } from "../../api";
import { VoltageFilter } from "../../features/planning-dashboard/components/VoltageFilter";
import { Chip } from "../../components/Chip";
import TaxonomyNodesTable from "../../features/planning-dashboard/components/TaxonomyNodesTable";
import { useDashboardStore } from "../../features/planning-dashboard/stores/DashboardStore";
import { useExportCircuits } from "../../features/planning-dashboard/hooks/useExportCircuits";
import {
  EXPORT_CIRCUITS_ERROR_MESSAGE,
  EXPORT_CIRCUITS_SUCCESS_MESSAGE,
  dashboardDataPairs,
} from "../../features/planning-dashboard/constants";

const WorkOrderBulk = lazy(() => import("../../features/planning-mode/components/WorkOrderBulk/WorkOrderBulk"));
const AssignToGF = lazy(() => import("../../features/planning-dashboard/components/AssignToGF"));
const AddToPlan = lazy(() => import("../../features/planning-mode/components/AddToPlan"));
const RemoveFromPlan = lazy(() => import("../../features/planning-mode/components/RemoveFromPlan"));
const CostBenefitBreakdown = lazy(() => import("../../features/planning-dashboard/components/CostBenefitBreakdown"));
const ConfirmExportDialog = lazy(() => import("../../features/planning-dashboard/components/ConfirmExportDialog"));

type ModalType = "Add To Plan" | "Generate Work Orders" | "Assign To GF" | "Remove From Plan";

export const PlanningDashboard = () => {
  const [searchText, setSearchText] = useState("");
  const [showModalType, setShowModalType] = useState<ModalType | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<StatusesType>("Unscheduled");
  const [selectedCircuitId, setSelectedCircuitId] = useState<string | null>(null);
  const [voltageFilters, setVoltageFilters] = useState<[number | undefined, number | undefined] | undefined>(undefined);
  const [showVoltageFilter, setShowVoltageFilter] = useState(false);
  const [showConfirmExport, setShowConfirmExport] = useState(false);
  const selectedRows = useDashboardStore((store) => store.selectedRows);
  const filterWrapperRef = useRef<HTMLDivElement | null>(null);
  const queryClient = useQueryClient();
  useClickOutside(filterWrapperRef, () => showVoltageFilter && setShowVoltageFilter(false));
  const setMessage = useMessageStore((store) => store.setErrorMessage);
  const selectedPlan = usePlanningStore((store) => store.selectedPlan);
  const selectedProgram = usePlanningStore((store) => store.selectedProgram);
  const setSelectedPlan = usePlanningStore((store) => store.setSelectedPlan);
  const setSelectedProgram = usePlanningStore((store) => store.setSelectedProgram);
  const { data: workTypes } = useCustomQuery<WorkTypeResponse[]>(getWorkTypesApiUrl());
  const { mutateAsync: exportToCSV, isLoading } = useExportCircuits(
    dashboardDataPairs.find((item) => item.name === "Dashboard - Unscheduled")?.id!,
    selectedProgram?.programId!
  );
  const { data: plans } = usePlans();
  const {
    data: programsForPlan,
    isFetched: areProgramsFetched,
    isLoading: areProgramsLoading,
  } = useProgramsForPlan(selectedPlan?.id);
  const { data: programsLiteData } = useProgramsLite(selectedPlan?.id);
  const {
    data,
    refetch: getDashboardData,
    isLoading: isDashboardDataLoading,
    isFetched: isDashboardDataFetched,
    isFetching: isDashboardDataFetching,
  } = useDashboardReportsData({ programId: selectedProgram?.programId });
  const tableData = useMemo(() => mapTableData(data), [data]);

  const { isLoading: loadingWorkOrderPreview, data: workOrderPreviewData } = useWorkOrderPreview(
    selectedPlan?.id,
    selectedProgram?.programId
  );
  const { mutateAsync: workOrdersBulkCreate, isLoading: woSubmitting } = useWorkOrderBulkCreate();
  const { mutateAsync: bulkAssign } = useBulkAssign();

  const tData = useMemo(() => {
    return (tableData?.[selectedStatus] || [])
      .filter((item) => filterBySearchTerm(item, searchText))
      .filter((item) => filterByVoltage(item.voltage, voltageFilters?.[0], voltageFilters?.[1]));
  }, [tableData, selectedStatus, searchText, voltageFilters]);

  const closeModal = () => setShowModalType(null);

  const onCtaClick = () => {
    switch (selectedStatus) {
      case "Unscheduled":
        return setShowModalType("Add To Plan");
      case "Plan":
        return setShowModalType("Generate Work Orders");
      case "Unassigned":
      case "GF Assigned":
        return setShowModalType("Assign To GF");
    }
  };

  const handleWorkOrderCreate = async ({ bulkWorkOrderDto, workOrderCount }: CreateWorkOrderData) => {
    try {
      const bulkCreateRequestData = {
        programId: selectedProgram?.programId ?? "",
        woData: {
          crewId: bulkWorkOrderDto?.crewId,
          vendorId: bulkWorkOrderDto?.vendorId,
          spanIds: bulkWorkOrderDto?.spanIds,
        },
      };
      await workOrdersBulkCreate(bulkCreateRequestData);
      const successMessage =
        workOrderCount && workOrderCount > 2
          ? `${workOrderCount} work orders successfully created.`
          : `${workOrderCount} work order successfully created.`;

      setMessage(successMessage, MessageType.Success);
      getDashboardData();
      setShowModalType(null);
    } catch {
      setMessage(SOMETHING_WENT_WRONG_MESSAGE);
    }
  };

  const onBulkAssign = async (nodes: FeederType[]) => {
    try {
      const bulkAssignData = {
        programId: selectedProgram?.programId ?? "",
        nodes: nodes.map((item) => ({
          taxonomyNodeId: item.id,
          vendorId: item.vendorId,
          crewId: item.crewId,
        })),
      };
      await bulkAssign(bulkAssignData);

      getDashboardData();
      setShowModalType(null);
    } catch {
      setMessage(SOMETHING_WENT_WRONG_MESSAGE);
    }
  };

  const onConfirmExport = async () => {
    try {
      const data = await exportToCSV();
      fileDownload(data as string, "cut-plan.csv");
      setShowConfirmExport(false);
      setMessage(EXPORT_CIRCUITS_SUCCESS_MESSAGE, MessageType.Success);
    } catch {
      setMessage(EXPORT_CIRCUITS_ERROR_MESSAGE, MessageType.Error);
    }
  };

  useEffect(() => {
    if (areProgramsFetched && selectedProgram === undefined) {
      setSelectedProgram(programsForPlan?.programs?.[0]);
    }
  }, [areProgramsFetched, selectedProgram, programsForPlan?.programs, setSelectedProgram]);

  return (
    <PageWrapper flexDirection="column" pt="48px" height={"100%"}>
      <Header
        plans={plans}
        selectedPlan={selectedPlan}
        selectedProgram={selectedProgram}
        programs={programsForPlan?.programs}
        onCtaClick={onCtaClick}
        onSelectPlan={setSelectedPlan}
        onSelectProgram={setSelectedProgram}
        areProgramsLoading={areProgramsLoading}
        showRefreshArrow={!isDashboardDataLoading && isDashboardDataFetching}
      />

      <ContentWrapper>
        <MainContent>
          <StyledFlex flexDirection={"column"} mb={"8px"}>
            <StyledFlex justifyContent={"space-between"} height={"36px"}>
              <StyledFlex flexDirection={"column"}></StyledFlex>
              <StyledFlex gap={"8px"}>
                {selectedStatus === "Scheduled" ? (
                  <RemoveButton
                    Icon={Trash}
                    text="Remove"
                    disabled={selectedRows.length === 0}
                    onClick={() => setShowModalType("Remove From Plan")}
                  />
                ) : null}
                {shouldShowCta(selectedStatus) && (
                  <CtaButton variant="primary" width={250} disabled={selectedRows.length === 0} onClick={onCtaClick}>
                    {getCtaLabel(selectedStatus)}
                  </CtaButton>
                )}
              </StyledFlex>
            </StyledFlex>
            <StyledFlex justifyContent={"space-between"} mt={"8px"}>
              <SearchWrapper>
                <Magnifier />
                <Input
                  type="string"
                  placeholder="Search"
                  hasError={false}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                {searchText?.length > 0 && <CloseSearch onClick={() => setSearchText("")} />}
              </SearchWrapper>
              <StyledFlex position={"relative"} alignItems={"center"} gap={"8px"}>
                {voltageFilters !== undefined && (
                  <Chip
                    Icon={Filters}
                    type="Voltage"
                    value={`${voltageFilters[0] !== undefined ? voltageFilters[0] : 0} - ${
                      voltageFilters[1] !== undefined ? voltageFilters[1] : "Infinity"
                    }`}
                    onDelete={() => setVoltageFilters(undefined)}
                  />
                )}
                <StyledFlex ref={filterWrapperRef} alignItems={"center"}>
                  <FilterButton
                    Icon={Plus}
                    text="Add Filter"
                    onClick={() => setShowVoltageFilter(!showVoltageFilter)}
                  />
                  {showVoltageFilter && (
                    <VoltageFilter
                      voltageFilters={voltageFilters}
                      setVoltageFilters={setVoltageFilters}
                      closePopup={() => setShowVoltageFilter(false)}
                    />
                  )}
                  {selectedStatus === "Unscheduled" && (
                    <ExportButton Icon={ExportIcon} text="Export to CSV" onClick={() => setShowConfirmExport(true)} />
                  )}
                </StyledFlex>
              </StyledFlex>
            </StyledFlex>
          </StyledFlex>
          <StyledFlex p={"5px 0px"} gap={"12px"} flexWrap={"wrap"}>
            {isDashboardDataLoading ? (
              <StatusSkeleton />
            ) : (
              data?.results.map((item: any) => (
                <StatusRadio
                  key={item?.reportId ?? ""}
                  label={item?.reportName}
                  value={(item?.data as DashboardNodeDto[])?.length}
                  selected={selectedStatus === item?.reportName}
                  onClick={() => setSelectedStatus(item?.reportName as StatusesType)}
                />
              ))
            )}
          </StyledFlex>
          <TaxonomyNodesTable
            data={isDashboardDataLoading ? Array(10).fill({}) : tData}
            isFetched={isDashboardDataFetched}
            isDashboardDataLoading={isDashboardDataLoading}
            selectedProgramId={selectedProgram?.programId}
            selectedStatus={selectedStatus}
            setSelectedCircuitId={setSelectedCircuitId}
          />
        </MainContent>
      </ContentWrapper>
      <Suspense fallback={null}>
        {showModalType === "Add To Plan" && (
          <Modal closeModal={closeModal} title="Add to Plan" isHidden={false} width="448px">
            <AddToPlan
              selectedFeeders={(tableData?.[selectedStatus] || [])
                .filter((item) => selectedRows.some((sItem) => sItem.original.objectId === item.objectId))
                .map((item) => ({
                  id: item.taxonomyNodeId,
                  name: item.label,
                  riskScore: item.riskScore,
                }))}
              selectedPlan={selectedPlan}
              programsForPlan={programsLiteData}
              closeModal={closeModal}
              onSuccess={() => {
                getDashboardData();
                queryClient.invalidateQueries(PLANS_QUERY_KEY);
              }}
            />
          </Modal>
        )}
        {showModalType === "Remove From Plan" && (
          <Modal closeModal={closeModal} title="Remove from Plan" isHidden={false} width="448px">
            <RemoveFromPlan
              selectedFeeders={(tableData?.[selectedStatus] || [])
                .filter((item) => selectedRows.some((sItem) => sItem.original.objectId === item.objectId))
                .map((item) => ({
                  id: item.taxonomyNodeId,
                  name: item.label,
                  riskScore: item.riskScore,
                }))}
              selectedPlan={selectedPlan}
              selectedProgram={selectedProgram}
              closeModal={closeModal}
              onSuccess={() => {
                getDashboardData();
                queryClient.invalidateQueries(PLANS_QUERY_KEY);
              }}
            />
          </Modal>
        )}
        {showModalType === "Generate Work Orders" && (
          <Modal closeModal={closeModal} title="Generate Work Orders" isHidden={false} width="765px">
            {loadingWorkOrderPreview ? (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            ) : (
              <WorkOrderBulk
                circuits={workOrderPreviewData?.data.circuits?.filter((circuit) =>
                  selectedRows.some((item) => item.original.taxonomyNodeId === circuit.taxonomyNodeId)
                )}
                workTypes={workTypes}
                program={selectedProgram}
                isSubmitting={woSubmitting}
                onCreate={handleWorkOrderCreate}
                onCancel={closeModal}
              />
            )}
          </Modal>
        )}
        {showModalType === "Assign To GF" && (
          <Modal
            closeModal={closeModal}
            title="Assign"
            isHidden={false}
            width={selectedStatus === "Unassigned" ? "750px" : "550px"}
          >
            <AssignToGF
              selectedFeeders={(tableData?.[selectedStatus] || [])
                .filter((item) => selectedRows.some((sItem) => sItem.original.objectId === item.objectId))
                .map((item) => ({
                  id: item.taxonomyNodeId,
                  name: item.label,
                  riskScore: item.riskScore,
                }))}
              shouldSetVendors={selectedStatus === "Unassigned"}
              closeModal={closeModal}
              onSuccess={onBulkAssign}
            />
          </Modal>
        )}
        {selectedCircuitId && (
          <Modal
            closeModal={() => setSelectedCircuitId(null)}
            title="Cost Benefit Index Breakdown"
            width={"min(1350px, 70%)"}
            padding={"32px"}
            isHidden={false}
          >
            <CostBenefitBreakdown circuitId={selectedCircuitId} />
          </Modal>
        )}
        {showConfirmExport && (
          <ConfirmExportDialog
            closeModal={() => setShowConfirmExport(false)}
            onConfirm={onConfirmExport}
            isLoading={isLoading}
          />
        )}
      </Suspense>
    </PageWrapper>
  );
};
