import styled from "styled-components";
import { StyledFlex } from "../../../../assets/styles/flex.styled";

export const Wrapper = styled(StyledFlex)<{ width: string }>`
  background: var(--colors-white);
  box-shadow: 0px 20px 50px rgba(18, 27, 33, 0.2);
  border-radius: 8px;
  flex-direction: column;
  width: ${({ width }) => width ?? "100px"};
`;

export const Header = styled(StyledFlex)<{ padding?: string }>`
  --spacer: 16px;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: ${({ padding }) => (padding ? `var(--spacer) ${padding}` : "var(--spacer)")};

  &:after {
    content: "";
    display: inline-block;
    height: 1px;
    background-color: var(--colors-slate-200);
    position: absolute;
    bottom: 0;
    left: ${({ padding }) => padding ?? "var(--spacer)"};
    right: ${({ padding }) => padding ?? "var(--spacer)"};
  }
`;

export const Title = styled(StyledFlex)`
  font-weight: 600;
  font-size: 18px;
  color: var(--colors-slate-500);
`;

export const Content = styled(StyledFlex)`
  flex: 1;
  flex-direction: column;
  margin: 0 16px;
`;
